@import "../SassVars";

.content {
  .header-container {
    z-index: -1;
    position: relative;
    text-align: center;
    color: white;
    background-size: cover;
    background-repeat: no-repeat;
    background-position-x: center;

    @include for_breakpoint(desktop) {
      height: 50vh;
      background-attachment: fixed;
    }
    @include for_breakpoint(mobile) {
      height: 20vh;
    }
    @include for_breakpoint(tablet) {
      height: 35vh;
    }

    .header {
      font-size: 3rem;
      text-align: center;
      margin: auto;
    }
    .centered {
      position: absolute;
      top: 30%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  h1,
  h3 {
    margin-left: 1em;
  }

  .mainP {
    background-color: white;
    @include for_breakpoint(desktop tablet) {
      line-height: 1.5;
      font-size: 1.1rem;
      margin-left: 7em;
      margin-right: 3em;
      margin-bottom: 8em;
    }
    @include for_breakpoint(mobile) {
      line-height: 1.5;
      font-size: 1.1rem;
      margin-left: 3em;
      margin-right: 3em;
      margin-bottom: 8em;
    }
  }
  img {
    width: 100%;
  }
}
