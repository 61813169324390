@use "./SassVars";
@use "./App";
@use "./Components/CallToAction/CallToAction";
@use "./Components/NavBar/NavBar";
@use "./Components/pages";
@use "./Components/Form/form";
@use "./Components/Footer/footer";
@use "./Components/miniComponents/threeImages";
@use "./Components/miniComponents/imageBesideText";
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.linkout {
  color: black;
}
