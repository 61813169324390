@import "../../SassVars";
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap");

#CallToAction {
  background-color: rgb(255, 255, 255);
  display: flex;
  height: 20vh;

  h1 {
    width: 62%;
    margin: auto;
    font-size: 2.5rem;
    text-align: center;
    text-decoration: underline;
    text-decoration-color: rgb(250, 215, 10);
    text-underline-offset: 0.5em;
    @include for_breakpoint(mobile) {
      text-underline-offset: 0.2em;
      width: 50%;
    }
    color: $main-color;
    font-family: "Roboto", sans-serif;
  }
  .container {
    width: 38%;
    @include for_breakpoint(mobile) {
      width: 50%;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    .cta-num {
      display: flex;
      gap: 1em;
      align-items: center;
      width: 75%;
      font-size: 1rem;
      @include for_breakpoint(mobile) {
        font-size: 0.85rem;
      }
      margin: auto;
      text-align: left;
      border-radius: 1rem;
      img {
        width: 2em;
      }
      &:hover {
        color: $secondary-color-light;
        cursor: pointer;
        font-size: 1.05em;
      }
    }
  }
}
