@import "../../SassVars";

#NavBar {
  z-index: 3;
  font-size: 1.5rem;
  color: white;
  background-color: $secondary-color-light;
  height: 2.2em;
  display: flex;
  justify-content: space-around;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  position: sticky;
  top: 0;

  .menuItem {
    width: 25vw;
    text-align: center;
    font-size: 1.5rem;
    .link {
      text-decoration: none;
      color: white;
      &:hover {
        color: rgb(233, 233, 233);
        font-size: 1.15em;
      }
    }
    .dropdown-content {
      transition: opacity 0.95s ease-in-out;
      opacity: 0;
      position: absolute;
      background-color: $secondary-color-light;
      padding-top: 0.75em;
      min-width: 25vw;
      z-index: 1;
      > .menuItem {
        height: 4rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      .link {
        float: none;
        color: white;
        text-decoration: none;
        display: block;
        text-align: center;
        @include for_breakpoint(mobile) {
          font-size: 0.75em;
          padding: 0.55em;
        }
      }
      &:hover {
        background-color: $secondary-color-light;
      }
      &:not(:hover) {
        opacity: 0;
      }
    }

    & .dropdown {
      float: left;
      overflow: hidden;
    }
    &:hover .dropdown-content {
      opacity: 1;
      visibility: visible;
      display: block;
    }
  }

  .menuItem .link:hover,
  .dropdown:hover .link {
    background-color: $secondary-color-light;
  }
  .noLink {
    cursor: default;
    #carat {
      transition: transform 0.75s ease-in-out;
      display: inline-block;
      &:not(:hover) {
        transform: rotate(0deg);
      }
    }
    &:hover {
      #carat {
        transform: rotate(-540deg);
      }
    }
  }
}
