@import "./SassVars";

:root {
  @include for_breakpoint(mobile) {
    font-size: 10px;
  }
  @include for_breakpoint(tablet) {
    font-size: 13px;
  }
  @include for_breakpoint(desktop) {
    font-size: 16px;
  }
}
